import React from "react"
import Landing from "../landing"
import SEO from "../components/seo"

const IndexPage = (): JSX.Element => {
  return (
    <>
      <SEO title="Home" />
      <Landing />
    </>
  )
}

export default IndexPage
